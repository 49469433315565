<template>
	<div>
		<div class="header"><appHeader></appHeader></div>
		<div style="height: 84px;"></div>
		<div class="row cc main-container">
			<div class="row page-main">
				<div style="width: 340px;margin-right: 10px;">
					<div class="sidebar-item">
						<keep-alive><appUserInfo :key="$route.query.user_id || ''"></appUserInfo></keep-alive>
					</div>
				</div>
				<div style="width: 857px;height: 100%;">
					<keep-alive><slot /></keep-alive>
				</div>
			</div>
		</div>
		<appSideToolbar></appSideToolbar>
	</div>
</template>

<script>
import appHeader from './components/header.vue';
import appUserInfo from './components/userInfo.vue';
import appSideToolbar from './components/sideToolbar.vue';
export default {
	components: { appHeader, appUserInfo, appSideToolbar },
	data() {
		return {};
	}
};
</script>
<style type="text/css">
body {
	background-color: #f6f6f6;
}
</style>
<style scoped lang="less">
.page-main {
	width: 1207px;
}
.header {
	position: fixed;
	top: 0;
	z-index: 101;
	width: 100%;
}
.sidebar-item {
	position: fixed;
	top: 84px;
	z-index: 100;
}
</style>
